<template>
    <div class="view pa24" style="width: 100%;">
        <el-table :data="dataList">
        <el-table-column
          prop="amount"
          label="满足金额(元)"
          width="400"
        >
          <template slot-scope="scope">
            <el-input-number size="medium" :precision="2" :step="0.1" :max="86416515655" v-model="scope.row.amount" />
          </template>
        </el-table-column>
        <el-table-column
          prop="valueEn"
          label="优惠金额(元)"
          width="400"
        >
          <template slot-scope="scope">
            <el-input-number size="medium" :precision="2" :step="0.1" :max="86416515655" v-model="scope.row.reduceAmount" />
          </template>
        </el-table-column>
       
        <el-table-column
          label="操作"
          width="130px"
        >
          <template slot-scope="scope" >
            <div  style="text-align: center;">
            <i v-if="scope.$index!==0" class="el-icon-top icon  jl" @click="clickUp(scope.$index)" />
            <i v-if="scope.$index!==dataList.length-1" class="el-icon-bottom icon jl" @click="clickDown(scope.$index)" />
            <i class="el-icon-plus icon jl" @click="addItem(scope.$index)" />
            <i class="el-icon-delete icon del jl" @click="delItem(scope.$index)" />
            </div>
           
          </template>
        </el-table-column>
       </el-table> 
      <!-- <el-button type="text" style="text-align: center;width: 100%" @click="addItem(-1)">添加一行</el-button> -->
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <!-- <el-button type="text" @click="dialogShow=false">取消</el-button> -->
        <el-button type="primary" style="margin-top: 10px;" @click="save">保存</el-button>
      </div> 
  
    </div>
  </template>
  
  <script>
  import {get,post} from "@/utils/request";
  export default {
    name: "reductionActivity",
    components: {
     
    },
    props: {
      drafts: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dataList: [{ amount: 0, reduceAmount: 0,}],
        type: 'add',
      };
    },
    created() {
      // this.getGoodsInfoPCShop();
      if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
        this.hasPermi = false
      }
    },
    computed: {
      // setGetDataTimeSec() {
      //   return (val) => getDataTimeSec(val);
      // },
    },
   
    mounted(){
        this.initDataList()
    },
    methods: {
        addItem(index) {
      var item = { amount: 0,
            reduceAmount: 0,}
      if (index >= 0) {
        this.dataList.splice(index + 1, 0, item)
      } else {
        console.log(this.dataList)
        this.dataList.push(item)
      }
    },
    delItem(index) {
      this.dataList.splice(index, 1)
    },
    clickUp(index) {
      if (index === 0) {
        this.$message('不能上移了')
        return
      }
      const arr = this.dataList
      arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]))
    },
    // 下移
    clickDown(index) {
      if (index === this.dataList.length - 1) {
        this.$message('不能下移了')
        return
      }
      const arr = this.dataList
      arr.splice(index, 1, ...arr.splice(index + 1, 1, arr[index]))
    },
    initDataList() {
      get('/reduction', {cId:JSON.parse(localStorage.getItem('info')).companyId}).then(res => {
        console.log('结果', res)
        this.dataList = res
        if(this.dataList.length <= 0){
            this.dataList.push({ amount: 0, reduceAmount: 0,})
        }else{
            this.$nextTick(()=>{
                this.dataList.forEach(item=>{
                console.log(((item.amount)/100))
                item.amount = ((item.amount)/100).toFixed(2)
                item.reduceAmount=(item.reduceAmount/100).toFixed(2)
            })
            })
            
        }
      })
    },
    save() {
      post('/reduction', { list: this.dataList }).then(res => {
        this.initDataList()
        this.$message({
                  type:'success',
                  message: "保存成功",
                })
        console.log(res)
      })
    }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .jl{
    display: inline-block;
    margin-right: 2px;
  }
  /deep/.cell{
    text-align: center;
  }
  </style>
  